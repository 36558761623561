/*eslint-disable*/
import React from "react";
import { Box, Flex, Text, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { AddIcon, BellIcon, SearchIcon, SettingsIcon, HamburgerIcon } from "@chakra-ui/icons";
import PropTypes from "prop-types";

export default function Footer(props) {
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  const displayOnMobile = useBreakpointValue({ base: "flex", md: "none" });
  return (
    <>
      <Flex
        display={displayOnMobile}
        position="fixed"
        top={0}
        width="100%"
        backgroundColor="teal.200"
        padding={4}
        justify="space-between"
        alignItems="center"
        zIndex={1} // Set a high z-index to ensure it's always on top
      >
        {/* Left side logo icon */}
        <Box>
          <img
            src="https://webniter.com/assets/img/webniter-logo.png"
            alt="Logo"
            width={40}
            height={40}
          />
        </Box>

        {/* Centered text */}
        <Box>
          <Text color="white" fontSize="xl" fontWeight="bold">
            Webniter
          </Text>
        </Box>

        {/* Right side button with three bars */}
        <IconButton aria-label="Menu" icon={<HamburgerIcon />} variant="ghost" color="white" />
      </Flex>

      <Flex
        flexDirection={{
          base: "column",
          xl: "row",
        }}
        alignItems={{
          base: "center",
          xl: "start",
        }}
        justifyContent="space-between"
        px="30px"
        pb="20px"
      >
        <Text
          color="white.400"
          textAlign={{
            base: "center",
            xl: "start",
          }}
          mb={{ base: "20px", xl: "0px" }}
        >
          &copy; {1900 + new Date().getYear()},{" "}
          <Text as="span">
            {document.documentElement.dir === "rtl" ? " مصنوع من ❤️ بواسطة" : "Made with ❤️ "}
          </Text>
          {document.documentElement.dir === "rtl" ? "للحصول على ويب أفضل" : " for a better web"}
        </Text>
      </Flex>

      <Flex
        zIndex={1}
        display={displayOnMobile}
        position="fixed"
        bottom={0}
        width="100%"
        backgroundColor="teal.200"
        padding={4}
        justify="space-around"
        borderTop="1px solid"
        borderColor="gray.300"
      >
        <IconButton aria-label="Add" icon={<AddIcon />} variant="ghost" color="white" />
        <IconButton aria-label="Search" icon={<SearchIcon />} variant="ghost" color="white" />
        <IconButton aria-label="Notifications" icon={<BellIcon />} variant="ghost" color="white" />
        <IconButton aria-label="Settings" icon={<SettingsIcon />} variant="ghost" color="white" />
      </Flex>
    </>
  );
}
